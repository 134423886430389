import { authLogout } from '@/request/api';
import { clearStorage } from '@/utils';
import { useRouter } from 'vue-router';
import useNavStore from '@/store/useNavStore';

export default function outLoginHook() {
    const router = useRouter();
    const navStore = useNavStore();

    // 退出登录
    function outLoginFun() {
        authLogout();
        navStore.reset();// 重置头部导航缓存
        setTimeout(() => {
            clearStorage();
            router.replace('/login');
        }, 100)
    }
    return { outLoginFun }
}