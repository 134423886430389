<template>
	<header>
		<section class="logo">
			<div class="img_box">
				<img :src="logo" alt="">
			</div>
		</section>
		<section class="nav">
			<div class="nav_item" v-for="(item, index) in navList" :key="item.path"
				:class="[$route.path === item.path ? 'active' : '', item.path === '/home' ? 'home' : '']"
				@click="onNav(item)">
				<el-tooltip effect="light" :content="item.meta.title" :disabled="item.meta.title.length <= 2">
					<span class="textOverflowOne">{{ item.meta.title }}</span>
				</el-tooltip>
				<div class="close" v-if="item.path !== '/home'" @click.stop="deleteNav(index)">
					<CloseBold class="icon" />
				</div>
			</div>
		</section>
		<section class="ri_box">
			<ul>
				<!-- <el-popover placement="bottom-end" trigger="hover">
					<template #reference>
						<li>
							<i class="iconfont icon-tongji"></i>
							<span>{{ rank.overRank }}</span>
						</li>
					</template>
					<div class="header_rank_list_popover">
						<h1>公司排名</h1>
						<div class="list_item" v-for="item in rank.companyRank" :key="item.appId">
							<i class="iconfont icon-medal"></i>
							<span>{{ item.companyName }}</span>
						</div>
					</div>
				</el-popover>
				<el-popover placement="bottom-end" trigger="hover">
					<template #reference>
						<li>
							<i class="iconfont icon-tongji"></i>
							<span>{{ rank.deptRank }}</span>
						</li>
					</template>
					<div class="header_rank_list_popover">
						<h1>组织内排名</h1>
						<div class="list_item" v-for="item in rank.userRank" :key="item.userId">
							<i class="iconfont icon-medal"></i>
							<span>{{ item.nickname }}</span>
						</div>
					</div>
				</el-popover> -->
				<el-tooltip effect="light" content="平台排名" placement="bottom-end">
					<li>
						<i class="iconfont icon-tongji"></i>
						<span>{{ rank.overRank }}</span>
					</li>
				</el-tooltip>
				<el-tooltip effect="light" content="组织内排名" placement="bottom-end">
					<li>
						<i class="iconfont icon-shuxingtu"></i>
						<span>{{ rank.deptRank }}</span>
					</li>
				</el-tooltip>
				<el-tooltip effect="light" content="登录次数" placement="bottom-end">
					<li>
						<i class="iconfont icon-denglu"></i>
						<span>{{ rank.loginNum }}</span>
					</li>
				</el-tooltip>
			</ul>
			<el-popover placement="bottom-end" trigger="hover" :width="180">
				<template #reference>
					<div class="user">
						<i class="iconfont icon-jurassic_user"></i>
					</div>
				</template>
				<div class="header_user_popover">
					<p class="welcome">欢迎回来<span>{{ userName }}</span></p>
					<div class="menus">
						<!-- <div class="li" @click="$router.push('/like')">
							<div class="icon_box"><i class="iconfont icon-shoucangguanzhu"></i></div>
							<p>关注偏好</p>
						</div> -->
						<div class="li" @click="$router.push('/password')">
							<div class="icon_box"><i class="iconfont icon-mima"></i></div>
							<p>修改密码</p>
						</div>
						<div class="li" @click="outLoginFun">
							<div class="icon_box"><i class="iconfont icon-tuichuzhuxiao"></i></div>
							<p>退出登录</p>
						</div>
					</div>
				</div>
			</el-popover>
		</section>
	</header>
</template>

<script setup>
import { ref } from "vue";
import { getStorage } from "@/utils";
import { CloseBold } from '@element-plus/icons-vue';
import { storeToRefs } from 'pinia';
import useNavStore from '@/store/useNavStore';
import { useRouter, useRoute } from 'vue-router';
import outLoginHook from '@/utils/outLoginHook';
import { apiUserRank } from '@/request/api';

const $router = useRouter();
const $route = useRoute();

// nav导航数据
const navStore = useNavStore();
const { navList } = storeToRefs(navStore);

const userName = ref(getStorage("name") || "admin");// 用户名
const logo = ref(getStorage("logo"));// logo
const rank = ref({});

// 点击nav
function onNav(to) {
	$router.push(to);
}

// 删除nav
function deleteNav(index) {
	navStore.delNav(index, $route.path);
}

// 退出登录
const { outLoginFun } = outLoginHook();

// 获取排名
(async function getRank() {
	const { data } = await apiUserRank();
	rank.value = data || {};
	if (rank.value.companyRank.length > 3) {
		rank.value.companyRank = rank.value.companyRank.slice(0, 3);
	}
	if (rank.value.userRank.length > 3) {
		rank.value.userRank = rank.value.userRank.slice(0, 3);
	}
})()
</script>


<style lang="scss" scoped>
header {
	height: var(--home-header-height);
	width: 100%;
	background-color: #2C2D2D;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 0 10px #f2f2f2;

	.logo {
		width: 152px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 2px;

		.img_box {
			max-width: 120px;
			min-width: 62px;
			height: 62px;
			background-color: #F1F5FA;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px 8px 0 0;
			padding: 5px 10px;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}
	}

	.nav {
		flex: 1;
		height: 100%;
		padding-top: 16px;
		display: flex;
		margin-left: 2px;

		.nav_item {
			flex: 1;
			width: 0;
			max-width: 150px;
			min-width: 50px;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 0 15px;
			position: relative;
			cursor: pointer;

			&.home {
				padding: 0 24px;
				text-align: center;
			}

			&::before {
				content: '';
				width: 1px;
				height: 20px;
				background-color: #464646;
				position: absolute;
				left: 0;
			}

			&:first-child {
				&::before {
					display: none;
				}
			}

			span {
				flex: 1;
				display: inline-block;
				white-space: nowrap;
				color: rgba(#fff, 0.65);
				font-size: 16px;
				user-select: none;
			}

			.close {
				width: 26px;
				height: 26px;
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 5px;

				.icon {
					width: 1em;
					height: 1em;
					color: rgba(#fff, 0.65);
				}
			}

			&:hover {
				.close {
					background-color: #E4EEFB;

					.icon {
						color: var(--primary-color);
					}
				}
			}

			&.active {
				width: 100px;
				background-color: #F1F5FA;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;

				span {
					color: #2F333E;
					font-weight: 500;
				}

				.close {
					.icon {
						color: var(--primary-color);
					}
				}

				&::before,
				&+::before {
					display: none;
				}
			}
		}
	}

	.ri_box {
		width: auto;
		display: flex;
		align-items: center;
		padding-right: 15px;

		ul {
			display: flex;

			li {
				margin-left: 20px;
				display: flex;
				align-items: center;
				cursor: pointer;
				color: #070A16;

				&:first-child {
					margin-left: 0;
				}

				span {
					font-size: 16px;
					color: #D8D8D8;
				}

				.iconfont {
					font-size: 24px;
					margin-right: 5px;
					color: #D8D8D8;
				}
			}
		}

		.user {
			width: 40px;
			height: 40px;
			background-color: #1D1D1D;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 30px;

			.iconfont {
				color: #D8D8D8;
				font-size: 22px;
			}
		}
	}
}

.header_user_popover {
	.welcome {
		color: #3A3D47;
		font-size: 16px;

		span {
			font-size: inherit;
			color: var(--primary-color);
		}
	}

	.menus {
		display: flex;
		justify-content: space-between;
		margin-top: 8px;

		.li {
			width: 72px;
			height: 72px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;

			.icon_box {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #262626;
				text-align: center;

				.iconfont {
					font-size: 16px;
				}
			}

			p {
				display: block;
				width: 72px;
				padding-top: 2px;
				text-align: center;
			}

			&:hover {
				background-color: #F5F5F5;

				.icon_box {
					border-color: var(--primary-color);

					.iconfont {
						color: var(--primary-color);
					}
				}
			}
		}
	}
}

.header_rank_list_popover {
	h1 {
		color: #2C2D2D;
		font-size: 14px;
		font-weight: normal;
		padding-bottom: 4px;
		display: flex;
		align-items: center;
		position: relative;

		&::before {
			display: block;
			content: '';
			width: 4px;
			height: 14px;
			background-color: #2C2D2D;
			margin-right: 8px;
			margin-left: 2px;
		}
	}

	.list_item {
		padding: 2px 0;
		display: flex;
		align-items: center;
		padding-top: 4px;

		.iconfont {
			font-size: 18px;
		}

		span {
			margin-left: 4px;
		}

		&:nth-child(2) {
			.iconfont {
				color: #FFD700;
			}
		}

		&:nth-child(3) {
			.iconfont {
				color: #625B57;
			}
		}

		&:nth-child(4) {
			.iconfont {
				color: #B87333;
			}
		}
	}
}
</style>